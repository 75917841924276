.background {
    position: fixed;
    z-index: 980;
    top: 0;
    overflow: hidden;
    width: 100vw;
    height: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #6c567b;
    opacity: 0.8;
}


form {
    margin-left: 10%;
    position: fixed;
    top: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 990;
    width: 300px;
    height: 150px;
    border-radius: 5px;
    box-shadow: 1px 2px 5px 1px #f67280;

    background: white;
}

form > input {
    background: none;
    outline: none;
    border: none;
    border-bottom: 1px solid #f67280;

    padding: 0.5rem;
    font-size: 1.125rem;
    line-height: 1.5;
}


form > button {
    padding-top: 20px;
    background: none;
    outline: none;
    border: none;
    color: #f67280;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1.5rem;
    display: flex;
    align-items: center; 
    cursor: pointer;
    transition: 0.1 background ease-in;
}


.rewrite > svg {
    cursor: pointer;
    padding-top: 20px;
    color: #f67280;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1.5rem;
}